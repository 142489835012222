import React, { useRef, useState, useLayoutEffect } from 'react';
import utils from './utils';
import Element from './element';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { format } from 'date-fns';

function Chart(props) {
  const chart = useRef(null);
  let [data, setData] = useState({});
  let [crud, setCrud] = useState(props.crud ? props.crud : {});
  let [view, setView] = useState({});
  let [index, setIndex] = useState(0);
  let [loading, setLoading] = useState(false);
  let [recursive, setRecursive] = useState(props.recursive != true);
  let colors_cache = {};
  let [sunburstgraph, setsunburstgraph] = useState(false);

  let type = props.type ? props.type : 'line';
  let parent = props.parent;
  let height = props.height ? props.height : "400px";
  let isPercentual = props.isPercentual ? props.isPercentual : false;
  let itemKey = props.itemKey ? props.itemKey : 'key';
  let itemValue = props.itemValue ? props.itemValue : 'value';
  let itemGroup = props.itemGroup ? props.itemGroup : 'group';
  let itemLabel = props.itemLabel ? props.itemLabel : 'label';

  const getRandomColor = function () {
    var letters = 'BCDEF'.split('');
    var color = '#';

    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  const getItemColor = function () {
    let color = null;
    let x = ["#E06055", "#F06292", "#BA68C8", "#9575CD", "#7986CB", "#5E97F6", "#4FC3F7", "#4DD0E1", "#4DB6AC", "#57BB8A", "#9CCC65", "#D4E157", "#FDD835", "#F6BF26", "#FFA726", "#FF8A65", "#C2C2C2", "#90A4AE", "#A1887F", "#A3A3A3", "#AFB6E0", "#B39DDB", "#80DEEA", "#BCAAA4", "#C2C2C2", "#AED581"];

    for (const xi in x) {
      let xo = x[xi];

      if (!colors_cache[xo]) {
        colors_cache[xo] = xo;
        return xo;
      }
    }
    do {
      color = getRandomColor();
    } while (colors_cache[color]);
    colors_cache[color] = am4core.color(color);
    return colors_cache[color];
  };

  let colors = [
    am4core.color("#0072EC"),
    am4core.color("#04B90A")
  ];

  if (props.extendColorPalete === true) {
    colors = [
      am4core.color("#0072EC"),
      am4core.color("#04B90A"),
      am4core.color("#E06055"),
      am4core.color("#F06292"),
      am4core.color("#BA68C8"),
      am4core.color("#9575CD"),
      am4core.color("#7986CB"),
      am4core.color("#5E97F6"),
      am4core.color("#4FC3F7")
    ];
  }

  let getTypeChart = function (t) {
    let x = {};

    if (t === 'pie') {
      x.type = am4charts.PieChart;
      x.serie = new am4charts.PieSeries();
    } else if (t === 'line') {
      x.type = am4charts.XYChart;
      x.serie = new am4charts.LineSeries();
    } else if (t === 'map') {
      x.type = am4maps.MapChart;
      x.serie = new am4maps.MapPolygonSeries();
    } else if (t === 'radar') {
      x.type = am4charts.RadarChart;
      x.serie = new am4charts.RadarColumnSeries();
    } else if (t === 'sunburst') {
      x.type = am4plugins_sunburst.Sunburst;
      x.serie = new am4plugins_sunburst.SunburstSeries();
    } else {
      x.type = am4charts.XYChart;
      x.serie = new am4charts.LineSeries();
    }
    return x;
  };

  let ctype = getTypeChart(type);
  let serie = ctype.serie;

  crud.refresh = function () {
    setCrud(crud);
    setIndex(index + 1);
  }

  const onChangeDatePeriodo = function () {
    setPeriodo('outros');

    if (props.source) {
      props.source.refresh();
    }
    crud.refresh();
    refreshChart('outros');
  }

  const changePeriodo = function (p) {
    let dataUtils = new DateFnsUtils();
    let agora = new Date();
    //p == mes_atual default
    let dataInicial = dataUtils.startOfMonth(agora);
    let dataFinal = dataUtils.endOfMonth(agora);

    if (p === 'proximos') {
      dataInicial = agora;
      dataFinal = dataUtils.addDays(agora, 90);
    } else if (p === 'outros') {
      crud.showCalendarDialog(onChangeDatePeriodo);
      return false;
    } else if (p === 'mes_anterior') {
      dataInicial = dataUtils.startOfMonth(dataUtils.getPreviousMonth(agora));
      dataFinal = dataUtils.endOfMonth(dataUtils.getPreviousMonth(agora));
    } else if (p === 'trimestre') {
      dataInicial = dataUtils.startOfMonth(
        dataUtils.getPreviousMonth(
          dataUtils.getPreviousMonth(agora)
        )
      );
      dataFinal = dataUtils.endOfMonth(agora);
    }

    crud.changed.date_start = dataInicial.getTime();
    crud.changed.date_end = dataFinal.getTime();

    let dateStartIso = format( dataInicial, "yyyy-MM-dd'T'HH:mm:ss" );
    let dateEndIso = format( dataFinal, "yyyy-MM-dd'T'HH:mm:ss" );

    if (dateStartIso && dateStartIso !== '') {
      crud.changed.date_start_iso = dateStartIso;
      crud.selected.date_start_iso = dateStartIso;
      crud.data.date_start_iso = dateStartIso;
    }
    if (dateEndIso && dateEndIso !== '') {
      crud.changed.date_end_iso = dateEndIso;
      crud.selected.date_end_iso = dateEndIso;
      crud.data.date_end_iso = dateEndIso;
    }

    crud.selected.date_start = dataInicial.getTime();
    crud.selected.date_end = dataFinal.getTime();

    crud.data.date_start = dataInicial.getTime();
    crud.data.date_end = dataFinal.getTime();

    if (!crud.data[props.id]) {
      crud.data[props.id] = {};
    }
    crud.data[props.id].date_start = crud.data.date_start;
    crud.data[props.id].date_end = crud.data.date_end;

    crud.data[props.id].date_start_ansi = crud.data.date_start_ansi;
    crud.data[props.id].date_end_ansi = crud.data.date_end_ansi;

    setPeriodo(p);
    return true;
  };

  useLayoutEffect(() => {
    refreshChart();
  }, []);

  let space = props.space ? props.space : 12;
  let [periodo, setPeriodo] = useState(null);
  let [action, setAction] = useState(props.action);

  const refreshChart = function(periodo) {
    let x = null;
    let period = periodo ? periodo : props.period;

    if (!period) {
      period = 'mes_atual';
    }

    changePeriodo(period);

    if (type !== 'grid') {
      setLoading(true);
    }

    let url = props.size ? action + '?size=' + props.size : action;

    utils.execute(crud, url, null, function (res) {
      let data = res.data;
      setLoading(false);

      if (type === 'grid') {
        //faz nada
      } else {
        x = am4core.create(props.id, ctype.type);

        if (props.colors !== false) {
          x.colors.list = colors;
        }

        if (props.layout) {
          x.bottomAxesContainer.layout = props.layout;
          x.bottomAxesContainer.reverseOrder = true;
        }


        const addSerie = function (ch, data) {
          if (!data || data.length <= 0) {
            return;
          }
          // Add and configure Series
          const s = ch.series.push(new am4charts.PieSeries());

          s.dataFields.value = itemValue;
          s.dataFields.category = itemKey;

          if (props.randomColors) {
            for (const i in data) {
              const di = data[i];

              if (!di.color) {
                di.color = getItemColor();
              }
            }
            s.slices.template.propertyFields.fill = 'color';
          }

          if (props.colors !== false) {
            s.colors.list = colors;
          }

          // Disable ticks and labels
          s.labels.template.disabled = true;
          s.ticks.template.disabled = true;

          s.slices.template.strokeWidth = 2;
          s.slices.template.strokeOpacity = 1;
          s.slices.template.stroke = am4core.color("#fff");

          // Disable tooltips
          s.slices.template.tooltipText = props.tooltip ? props.tooltip : "{" + itemKey + "} {" + itemValue + "}";
          s.data = data;

          if (parent) {
            const todos = [];

            for (const i in data) {
              const di = data[i];
              const filhos = di.filhos;

              if (filhos) {
                for (const fi in filhos) {
                  const fo = filhos[fi];

                  fo.color = di.color;

                  todos.push(fo);
                }
              }
            }
            addSerie(ch, todos);
          }
          return s;
        }

        if (type === 'map') {
          // Set map definition
          x.geodata = am4geodata_worldLow;

          // Set projection
          x.projection = new am4maps.projections.Miller();

          // Create map polygon series
          serie = x.series.push(new am4maps.MapPolygonSeries());
          // Make map load polygon (like country names) data from GeoJSON
          serie.useGeodata = true;

          // Configure series
          var polygonTemplate = serie.mapPolygons.template;
          polygonTemplate.tooltipText = "{name}: {value}";
          polygonTemplate.fill = am4core.color("#F0F4F9");

          // Create hover state and set alternative fill color
          var hs = polygonTemplate.states.create("hover");
          hs.properties.fill = am4core.color("#003A7B");

          // Remove Antarctica
          serie.exclude = ["AQ"];

          for (const i in data) {
            const v = data[i];

            if (v.value > 0) {
              v.fill = am4core.color("#003A7B");
            }
          }
          serie.data = data;

          // Bind "fill" property to "fill" key in data
          polygonTemplate.propertyFields.fill = "fill";
        } else if (type === 'sunburst') {
          let ab = {};
          let oo = [];
          let maxLevel = 0;

          for (const i in data) {
            const vi = data[i];
            const nv = {};
            const name = vi[props.itemLabel];
            const level = vi[props.itemLevel];

            if (level > maxLevel) {
              maxLevel = level;
            }
            if (name) {
              nv.nickname = name.trim().split(' ')[0];
            }
            nv.name = name;
            nv.parent = vi[props.itemParent];
            nv.id = vi[props.itemKey];
            nv.value = vi[props.itemValue];
            nv[props.itemValue] = vi[props.itemValue];
            nv[props.itemLabel] = vi[props.itemLabel];

            ab[nv.id] = nv;
          }

          for (const i in ab) {
            const vi = ab[i];
            const parent = vi.parent;

            if (ab[parent]) {
              if (!ab[parent].children) {
                ab[parent].children = [];
              }
              ab[parent].children.push(vi);
            } else {
              oo.push(vi);
            }
          }
          data = oo;

          let chart = x;

          chart.data = oo;
          chart.fontSize = 0;
          chart.innerRadius = am4core.percent(10);
          chart.dataFields.value = "value";
          chart.dataFields.name = "name";
          chart.dataFields.children = "children";
          chart.colors.step = 2;

          var level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
          level0SeriesTemplate.hiddenInLegend = false;
          level0SeriesTemplate.slices.template.tooltipText = props.tooltip;
          chart.seriesTemplates.setKey("0", level0SeriesTemplate)

          // this makes labels to be hidden if they don't fit
          level0SeriesTemplate.labels.template.truncate = true;
          level0SeriesTemplate.labels.template.hideOversized = true;

          level0SeriesTemplate.labels.template.adapter.add("rotation", function (rotation, target) {
            target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
            target.maxHeight = Math.abs(target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius) / 2 * am4core.math.RADIANS);

            return rotation;
          })

          for (let index = 1; index < maxLevel; index++) {
            var level2SeriesTemplate = level0SeriesTemplate.clone();

            chart.seriesTemplates.setKey(index + "", level2SeriesTemplate)
            level2SeriesTemplate.hiddenInLegend = true;

            level2SeriesTemplate.fillOpacity = index % 2 == 0 ? 1 : 0.75;
          }
          setsunburstgraph(true);
        } else if (type === 'radar') {
          
          let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());

          categoryAxis.dataFields.category = "level";
          categoryAxis.renderer.labels.template.location = 0.5;
          categoryAxis.renderer.tooltipLocation = 0.5;
          categoryAxis.renderer.grid.template.disabled = true;
          categoryAxis.renderer.labels.template.disabled = true;

          let valueAxis = x.yAxes.push(new am4charts.ValueAxis());

          valueAxis.tooltip.disabled = true;
          valueAxis.renderer.labels.template.horizontalCenter = "left";
          valueAxis.renderer.grid.template.disabled = true;
          
          let aa = {};
          let ab = [];

          for (const a1 in data) {
            let av1 = data[a1][itemKey];
            let av2 = 'val_' + data[a1][itemGroup];
            let av4 = 'name_' + data[a1][itemGroup];
            let av3 = data[a1][itemValue];

            if (!aa[av1]) {
              aa[av1] = { level: av1 };
              ab.push(aa[av1]);
            }
            if (!aa[av1][av2]) {
              aa[av1][av2] = av3;
              aa[av1][av4] = data[a1][itemLabel];

              let ss = x.series.push(new am4charts.RadarColumnSeries());

              ss.name = av2;
              ss.dataFields.categoryX = "level";
              ss.dataFields.valueY = av2;
              ss.stroke = am4core.color("#ffffff");
              ss.columns.template.strokeOpacity = 0.2;
              ss.stacked = true;
              ss.sequencedInterpolation = true;
              ss.columns.template.width = am4core.percent(100);
              ss.columns.template.tooltipText = "{" + av4 + "}, Pontos: {valueY}";
            }
          }

          let chart = x;

          chart.seriesContainer.zIndex = -1;

          chart.scrollbarX = new am4core.Scrollbar();
          chart.scrollbarX.exportable = false;
          chart.scrollbarY = new am4core.Scrollbar();
          chart.scrollbarY.exportable = false;

          chart.cursor = new am4charts.RadarCursor();
          chart.cursor.xAxis = categoryAxis;
          chart.cursor.fullWidthXLine = true;
          chart.cursor.lineX.strokeOpacity = 0;
          chart.cursor.lineX.fillOpacity = 0.1;
          chart.cursor.lineX.fill = am4core.color("#000000");

          data = ab;
          x.data = ab;
        } else if (type === 'pie') {

          if (!recursive) {
            serie = addSerie(x, data);
          } else {
            let all = {};
            let parents = [];

            for (const ix in data) {
              let iv = data[ix];
              let ok = iv[props.fieldKey];

              if (ok) {
                all[ok] = iv;
              }
            }

            for (const ix in data) {
              let iv = data[ix];
              let pk = iv[props.parentFieldKey];

              if (!all[pk]) {
                parents.push(iv);
              } else {
                let pr = all[pk];

                if (!pr.filhos) {
                  pr.filhos = [];
                }
                pr.filhos.push(iv);
              }
            }
            data = parents;
            serie = addSerie(x, parents);
          }

          x.innerRadius = am4core.percent(40);

          if (props.legend !== false) {
            x.legend = new am4charts.Legend();
          }
        } else if (type === 'bar') {

          var categoryAxis = null;

          if (props.layout === 'horizontal') {
            categoryAxis = x.yAxes.push(new am4charts.CategoryAxis());
          } else {
            categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
          }

          categoryAxis.dataFields.category = "key";
          categoryAxis.renderer.grid.template.location = 0;
          categoryAxis.renderer.minGridDistance = 30;
          let valueAxis = null;
          if (props.layout === 'horizontal') {
            valueAxis = x.xAxes.push(new am4charts.ValueAxis());
          } else {
            valueAxis = x.yAxes.push(new am4charts.ValueAxis());
          }
          valueAxis.min = 0;
          if (isPercentual) {
            valueAxis.calculateTotals = true;
            valueAxis.max = 100;
            valueAxis.strictMinMax = true;
          }

          // Create series
          if (props.series) {
            const labels = props.labels ? props.labels : {};
            const y = props.series;
            const clickFunction = props.click;

            let groups = {};
            let list = [];
            let cat = {};

            for (let i in data) {
              let e = data[i];
              let k = e[y.key];
              let l = e[y.group];
              let o = e[y.value];

              cat[l] = l;

              if (!groups[k]) {
                groups[k] = {};
              }
              groups[k][l] = o;
              groups[k].key = k;
              groups[k].constante = e.constante;
            }

            for (let i in groups) {
              let e = groups[i];

              list.push(e);
            }

            for (let i in cat) {
              var s = x.series.push(new am4charts.ColumnSeries());

              s.columns.template.strokeWidth = 0;
              s.name = labels[i] ? labels[i] : i;

              if (props.layout === 'horizontal') {
                s.dataFields.categoryY = "key";
                s.dataFields.valueX = i;
              } else {
                s.dataFields.categoryX = "key";
                s.dataFields.valueY = i;
              }
              if (props.tooltip) {
                s.columns.template.tooltipText = props.tooltip;
              } else {
                if (props.layout === 'horizontal') {
                  s.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
                } else {
                  s.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                }
              }
              if (clickFunction) {
                s.columns.template.events.on("hit", function(ev) {
                  clickFunction.call(
                    ev, 
                    getParameter(groups, i)+
                    (crud.data[props.id] && crud.data[props.id].date_start ? '&date_start='+crud.data[props.id].date_start : '')+
                    (crud.data[props.id] && crud.data[props.id].date_end ? '&date_end='+crud.data[props.id].date_end : '')
                  );
                });
              }
            }

            data = list;

            x.legend = new am4charts.Legend();
            x.legend.useDefaultMarker = true;

            if (props.barSize) {
              let barSize = props.barSize;
              height = barSize * data.length;
              x.svgContainer.htmlElement.style.height = height + "px";
            }

          } else {
            serie = x.series.push(new am4charts.ColumnSeries());

            if (props.layout === 'horizontal') {
              serie.dataFields.valueX = "value";
              serie.dataFields.categoryY = "key";
            } else {
              serie.dataFields.valueY = "value";
              serie.dataFields.categoryX = "key";
            }

            if (props.tooltip) {
              serie.columns.template.tooltipText = props.tooltip;
            } else {
              if (props.layout === 'horizontal') {
                serie.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
              } else {
                serie.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
              }
            }
          }
        } else {
          let dateAxis = x.xAxes.push(new am4charts.DateAxis());
          dateAxis.renderer.grid.template.location = 0;

          let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
          valueAxis.tooltip.disabled = true;
          valueAxis.renderer.minWidth = 35;

          let serie = x.series.push(new am4charts.LineSeries());

          serie.dataFields.dateX = "key";
          serie.dataFields.valueY = "value";
          serie.tooltipText = "{valueY.value}";

          x.cursor = new am4charts.XYCursor();
        }
        x.data = data;
      }
    });

    chart.current = x;


    return () => {
      if (x != null) {
        x.dispose();
      }
    };
  }

  const getParameter = function(groups, att) {
    let returnKey = null;
    for (let i in groups) {
      let grp = groups[i];

      if (grp[att]) {
        returnKey = grp.constante ? grp.constante : grp.key;
        break;
      }
    }
    return returnKey;
  }

  const onClickPeriodo = function (p) {
    if (changePeriodo(p)) {
      if (props.source) {
        props.source.refresh();
      }
      crud.refresh();
      refreshChart(p);
    }
  };

  const onClickFiltro = function (p) {
    setAction(p);

    if (props.source) {
      props.source.list.url = p;
    }

    props.source.refresh();
    crud.refresh();
  };

  const onClickAction = function (p) {
    let click = p.click;

    if (click) {
      let url = click.redirect;

      window.location.href = url;
    }
  };

  const labelFilter = function (a, b) {
    const l = typeof b === 'object' ? b.label : b;

    return utils.lng(a, l);
  }

  return (
    <div className={'ui-col ui-col-' + space + ' x-' + index}>
      <div className={'ui-chart ui-chart-' + type + ' ' + (props.styleClass ? props.styleClass : '')}>
        {props.title &&
          <div className="ui-chart-top">
            <div className="ui-chart-title">
              {props.title}
            </div>
            {props.periods &&
              <div className="ui-chart-actions">
                {Object.entries(props.periods).map((el, index) => (
                  <Button onClick={() => {
                    onClickPeriodo(el[0])
                  }} variant="contained" color="default"
                    className={'ui-chart-act ' + (periodo === el[0] ? 'ui-chart-sel' : '')} >
                    {utils.lng(el[0], el[1])}
                  </Button>
                ))}
              </div>
            }
            {props.actions &&
              <div className="ui-chart-actions">
                {Object.entries(props.actions).map((el, index) => (
                  <div onClick={() => {
                    onClickAction(el[1])
                  }} className={'ui-chart-act ' + (action === el[0] ? 'ui-chart-sel' : '')}>
                    {utils.lng(el[0], el[1].label)}
                  </div>
                ))}
              </div>
            }
          </div>
        }
        {!props.children &&
          <div className="ui-chart-data" >
            {props.filters &&
              <div className="ui-chart-filters">
                {Object.entries(props.filters).map((el, index) => (
                  <div onClick={() => {
                    onClickFiltro(el[0])
                  }} className={'ui-chart-filter ' + (action === el[0] ? 'ui-chart-sel' : '')}>
                    {labelFilter(el[0], el[1])}
                  </div>
                ))}
              </div>
            }
            {loading &&
              <div className="ui-spinner-loading">
                <i className="fas fas fa-circle-notch fa-spin"></i>
              </div>
            }
            <div className={'ui-chart-inner ' + (sunburstgraph === true ? 'sun-classe' : '')} id={props.id} style={{ width: "99%", height: height }}>
              {type === 'grid' &&
                <Element value={props.source} crud={crud} view={view} data={crud.data} />
              }
            </div>
          </div>
        }
        {props.children &&
          <div className="ui-chart-children">
            {props.children}
          </div>
        }
      </div>
    </div>
  );
}

export default Chart;