import React, { useEffect, useState } from "react";
import View from './view';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Element from './element';
import utils from "./utils";
import { FontAwesomeIcon, FaPlus, FaTrash } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheck } from "@fortawesome/free-solid-svg-icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';


const CardConsultorOrigem = ({ consultor, onAdd, onRemove }) => (
  <div className="rede_dna-card">
    <div className="rede_dna-info" style={{textAlign: 'center'}}>
      <i className="fa fa-plus fa-3x" onClick={onAdd} style={{bottom: "110px"}}></i>
      <p>{utils.lng('adicionar_consultor', 'Adicionar Consultor')}</p>
    </div>
  </div>
);

const Card = ({ consultor, onAdd, onRemove }) => (
  <div className="rede_dna-card">
    <img src={consultor.avatar ? consultor.avatar : '/static/img/avatar.jpg'} alt={consultor.descricao} className="rede_dna-avatar" />
    <div className="rede_dna-info">
      <p>{consultor.codigo}</p>
      <p>{consultor.descricao}</p>
    </div>
    <i className="fa fa-trash fa-2x rede_dna-delete-btn" onClick={onRemove}></i>
    <i className="fa fa-plus fa-2x rede_dna-add-btn" onClick={onAdd}></i>
  </div>
);

const RedeDNATreeNode = ({ node, parent, onAdd, onRemove }) => (
  <div className="rede_dna-tree-node">
    <Card consultor={node.consultor} onAdd={() => onAdd(node)} onRemove={() => onRemove(node, parent)} />
    {node && node.filhos && node.filhos.length > 0 && (
      <div className="rede_dna-children" style={{ gridTemplateColumns: `repeat(${node.filhos.length}, 1fr)` }}>
        {node.filhos.map((child) => (
          <RedeDNATreeNode key={child.id} node={child} parent={node} onAdd={onAdd} onRemove={onRemove} />
        ))}
      </div>
    )}
  </div>
);

function CrudRedeDNA() {
  const user = utils.getUser();
  const clienteLogado = utils.getCliente();

  let [idRedeDNA, setIdRedeDNA] = useState(null);
  let [redeDNA, setRedeDNA] = useState({ nome: null, tipo: null, redeDnaConsultorOrigem: null, cliente: clienteLogado });

  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: redeDNA, changed: redeDNA });
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);

  let [listaConsultoresDialog, setListaConsultoresDialog] = useState([]);
  let [listaConsultoresExcluidos, setListaConsultoresExcluidos] = useState([]);

  let [permiteEditarCadastro, setPermiteEditarCadastro] = useState(true);

  let [clienteSelecionado, setClienteSelecionado] = useState(null);

  let urlParts = window.location.pathname.substring(1).split('/');


  let [showDialogSelecaoConsultor, setShowDialogSelecaoConsultor] = useState(false);

  const loadRedeDNA = function(idRedeDNA) {
    if (idRedeDNA && idRedeDNA > 0) {
      utils.execute(crud, 'RedeDNA/' + idRedeDNA + '?lazy=false&resume=false', null, function (res) {
        if (res.data && res.data.id > 0) {
          redeDNA = res.data;
          setRedeDNA(redeDNA);
          crud.data = redeDNA;
          crud.changed = redeDNA;

          changeClienteSelecionado();
          crud.refresh();
          // permiteEditarCadastro = user.perfil.descricao === 'SuperAdmin';
        }
      }, true);
    }
  };

  const changeClienteSelecionado = function(cliente) {
    if (cliente.consultor) {
      clienteSelecionado = cliente;
    } else if (cliente && cliente.id && cliente.id > 0) {
      clienteSelecionado = {id: 0, consultor: cliente};
    } else {
      clienteSelecionado = {id: 0, consultor: (redeDNA && redeDNA.cliente ? redeDNA.cliente : clienteLogado)};
    }
    setClienteSelecionado(clienteSelecionado);
  };

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  };

  useEffect(() => {
    if (!ready) {
      setReady(true);

      if (urlParts.length > 1) {
        idRedeDNA = urlParts[1];
        setIdRedeDNA(idRedeDNA);
        if (idRedeDNA && idRedeDNA > 0) {
          loadRedeDNA(idRedeDNA);
        }
      }
    }
  });

  crud.openRedeDna =function(el,args){
    reload(args.data.id);
  };

  let list = {
    layout: "table",
    search: true,
    filter: true,
    header: true,
    name: "rede_dna",
    field: "rede_dna",
    type: "list",
    space: 12,
    size: 10,
    edit: true,
    visible: true,
    required: false,
    entity: 'RedeDna',
    add: {
      redirect: '/rede_dna/0'
    },
    label: utils.lng("rede_dna","Rede DNA"),
    filters: {
      nome: {
        type: 'text',
        label: utils.lng("nome", "Nome")
      }
    },
    columns: {
      id: {
        layout: "span",
        styleClass: "ui-component ",
        label: "ID",
        name: "id",
        field: "id",
        type: "column",
        visible: true
      },
      ativo: {
        layout: "span", 
        styleClass: "ui-component ", 
        label: utils.lng("ativo", "Ativo"), 
        name: "ativo", 
        field: "ativo", 
        type: "boolean"
      },
      nome: {
        layout: "span",
        styleClass: "ui-component ",
        label: utils.lng("nome", "Nome"),
        name: "nome",
        field: "nome",
        type: "column",
        visible: true
      },
      redeDnaConsultorOrigem: {
        layout: "span",
        styleClass: "ui-component ",
        label: utils.lng("consultor_origem", "Consultor Origem"),
        icon: "fas fa-ellipsis-v",
        name: "redeDnaConsultorOrigem",
        field: "redeDnaConsultorOrigem",
        type: "column",
        entity: 'Cliente'
      },
      cliente: {
        layout: "span",
        styleClass: "ui-component ",
        label: utils.lng("cliente", "Cliente"),
        icon: "fas fa-ellipsis-v",
        name: "cliente",
        field: "cliente",
        type: "column",
        entity: 'Cliente'
      }
    },
    click: {
      action: "openRedeDna"
    },
    list: {
      url: "/RedeDna"+(user.perfil.descricao === 'SuperAdmin' ? "" : "?cliente="+clienteLogado.id),
      method: "GET"
    }
  };

  //

  let edit = {
    name: 'redeDnaEdit',
    disabled: !permiteEditarCadastro,
    label: utils.lng('cadastrar_rede_dna','Cadastrar Rede DNA'),
    type: 'legend',
    space: 12,
    elements: {
      nome: { field: 'nome', label: utils.lng('nome','Nome'), type: 'text', required: true, space: 12 },
      ativo: { field: 'ativo', disabled: (!redeDNA.id || redeDNA.id === 0), label: utils.lng('ativo','Ativo'), type: 'checkbox', required: false, space: 12 },
      tipo: {
        field: 'tipo',
        label: utils.lng('tipo','Tipo'), type: 'select', required: true, space: 12,
        list: {
          url: 'RedeDnaTipo',
          send: false
        }
      }
    }
  };

  const salvarRedeDNA = function() {
    let msgValidacao = validaRedeDNA();
    if (msgValidacao === '') {
      let actions = [];
      actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => { postRedeDNA(true, (res) => { if (res.data) { voltarListagem(); } })} });
      actions.push({ label: utils.lng('cancelar', 'Cancelar'), action: () => {} });
      if (window.dialog) {
        window.dialog.alert(utils.lng('confirm_cadastro_rede_dna','Deseja realmente cadastrar esta rede DNA?'), null, actions);
      }
    } else {
      showAlert(msgValidacao);
    }
  };

  const validaRedeDNA = function() {
    let formIncompleto = !crud.data.nome || !crud.data.tipo || !crud.data.redeDnaConsultorOrigem;
    let msg = '';
    if (formIncompleto) {
      msg = utils.lng('alguns_erros_foram_encontrados','Alguns erros foram encontrados. Verifique!');
    }
    return msg;
  };

  const postRedeDNA = function(returnAlert, completeFunction) {
    view.loadingStart();
    const request_post_rede = {};
        
    request_post_rede.crud = crud;
    request_post_rede.validate = true;
    request_post_rede.changed = crud.data;
    request_post_rede.element = {};
    request_post_rede.cfg = {
      alert: returnAlert,
      url: 'RedeDna' + (idRedeDNA && idRedeDNA > 0 ? '/'+idRedeDNA : ''),
      method: idRedeDNA > 0 ? 'PUT' : 'POST'
    };
    if (completeFunction) {
      request_post_rede.complete = completeFunction;
    }
    utils.call(request_post_rede);
  };

  const voltarListagem = function () {
    reload();
  };

  //

  let listaConsultoresFilhos = {
    header: true,
    name: "consultores_filhos",
    field: "consultores_filhos",
    search: true,
    size: 10,
    type: "list",
    layout: 'grid',
    space: 12,
    edit: true,
    visible: true,
    required: false,
    columns: {
      id: {
        label: 'ID',
        name: "id",
        field: "id",
        type: "text"
      },
      codigo_mundial: {
        label: utils.lng("codigo_mundial", "Código Mundial"), 
        name: "codigo_mundial", 
        field: "codigo_mundial", 
        type: "text",
        filter: true
      },
      nome: {
        label: utils.lng("nome", "Nome"), 
        name: "nome", 
        field: "nome", 
        type: "text",
        filter: true
      },
      tipo_cliente: {
        label: utils.lng("tipo", "Tipo"), 
        name: "tipo_cliente", 
        field: "tipo_cliente", 
        type: "text",
        filter: true
      },
      status_cliente: {
        label: utils.lng("status", "Status"), 
        name: "status_cliente", 
        field: "status_cliente", 
        type: "text"
      }
    },
    click: {
      action: "selecionaConsultor"
    },
    // data: listaConsultoresDialog
    list: {
      url: "listarConsultoresFilhos?id_cliente_pai="+(clienteSelecionado && clienteSelecionado.consultor ? clienteSelecionado.consultor.id : 0),
      method: "GET"
    }
  };

  const addConsultorClick = (parent) => {
    if (validaLimiteRede()) {
      changeClienteSelecionado(parent);
      switchShowDialogConsultor();
      // setTimeout(() => { buscaConsultores() }, 500);
    }
  };

  const validaLimiteRede = function () {
    let limiteOk = false
    if (!redeDNA.tipo) {
      showAlert(utils.lng('tipo_rede_obrigatorio', 'O Tipo da Rede é Obrigatório, Verifique!'));
    } else {
      let qtdeConsultores = somaConsultoresRede(redeDNA);
      let limiteIntegrantesRede = redeDNA.tipo.limiteIntegrantesRede ? redeDNA.tipo.limiteIntegrantesRede : 5;
      limiteOk = qtdeConsultores < limiteIntegrantesRede;
      if (!limiteOk) {
        showAlert(utils.lng('limite_rede_atingido', 'O limite de consultores permitidos na Rede já foi atingido!')+' ('+redeDNA.tipo.limiteIntegrantesRede+')');
      }
    }
    return limiteOk;
  };

  const somaConsultoresRede = function (redeDNA) {
    let qtdeConsultores = redeDNA.redeDnaConsultorOrigem ? 1 : 0;
    if (redeDNA.redeDnaConsultorOrigem) {
      qtdeConsultores += somaFilhos(redeDNA.redeDnaConsultorOrigem.filhos);
    }
    return qtdeConsultores;
  }

  const somaFilhos = function (listaFilhos) {
    let qtdeFilhos = listaFilhos ? listaFilhos.length : 0;
    if (qtdeFilhos > 0) {
      for (let i=0; i<qtdeFilhos; i++) {
        let child = listaFilhos[i];
        qtdeFilhos += child ? somaFilhos(child.filhos) : 0;
      }
    }
    return qtdeFilhos;
  }

  // const buscaConsultores = function() {
    // if (idRedeDNA && clienteSelecionado && clienteSelecionado.consultor) {
    //   let url = "listarConsultoresFilhos?id_cliente_pai="+clienteSelecionado.consultor.id;
    //   utils.execute(crud, url, null, function (res) {
    //     listaConsultoresDialog = res.data;
    //     setListaConsultoresDialog(listaConsultoresDialog);
        // switchShowDialogConsultor();
      // }, true);
    // }
  // };

  const switchShowDialogConsultor = function () {
    showDialogSelecaoConsultor = !showDialogSelecaoConsultor;
    setShowDialogSelecaoConsultor(showDialogSelecaoConsultor);
  };

  crud.selecionaConsultor = function(el, args) {
    if (args && args.data && args.data.id > 0) {
      let consultor = args.data;
      let newConsultorDNA = {
        id: 0,
        consultor: {
          id: consultor.id,
          descricao: consultor.nome,
          codigo: consultor.codigo_mundial,
          avatar: consultor.avatar
        },
        filhos: []
      };
      
      let isConsultorJaAdicionado = validaDuplicidadeConsultor(redeDNA.redeDnaConsultorOrigem, newConsultorDNA);
      if (!isConsultorJaAdicionado) {
        avancaAdicaoConsultor(clienteSelecionado, newConsultorDNA);
      }
    }
    switchShowDialogConsultor();
    crud.refresh();
  };

  const validaDuplicidadeConsultor = function(parent, newConsultorDNA) {
    let isDuplicado = false;
    if (parent && parent.filhos) {
      let listaDuplicados = parent.filhos.filter((child) => child.consultor.id === newConsultorDNA.consultor.id);
      isDuplicado = listaDuplicados.length > 0;
      if (isDuplicado) {
        showAlert(utils.lng('consultor_ja_inserido_rede', 'Consultor já está inserido nesta rede. Verifique!'));
      } else {
        for (let i=0; i<parent.filhos; i++) {
          let filho = parent.filhos[i];
          validaDuplicidadeConsultor(filho, newConsultorDNA);
        }
      }
    }
    return isDuplicado;
  };

  const avancaAdicaoConsultor = function(parent, newConsultorDNA) {
    utils.execute(crud, 'RedeDNAConsultor?consultor' + newConsultorDNA.consultor.id + '?lazy=false&resume=false', null, function (res) {
      if (res.data && res.data.id > 0) {
        showAlert(utils.lng('consultor_ja_inserido_em_outra_rede', 'Consultor já inserido em outra rede. Verifique!'));
      } else {
        addConsultorRede(parent, newConsultorDNA);
      }
    }, true);
  };

  const addConsultorRede = function (parent, newConsultorDNA) {
    let isConsultorOrigem = !redeDNA.redeDnaConsultorOrigem;
    if (isConsultorOrigem) {
      redeDNA.redeDnaConsultorOrigem = newConsultorDNA;
      if (redeDNA.id > 0) {
        postRedeDNA(false, (res) => {
          if (res.data) {
            reload(redeDNA.id);
          }
        });
      }
    } else {
      if (parent) {
        if (!parent.filhos) {
          parent.filhos = [];
        }
        parent.filhos.push(newConsultorDNA);
        if (redeDNA.id > 0) {
          salvarRedeDnaConsultor(parent);
        }
      }
    }
  };

  const salvarRedeDnaConsultor = function(consultorPai) {
    view.loadingStart();
    const request_save_consultor_rede = {};
        
    request_save_consultor_rede.crud = crud;
    request_save_consultor_rede.validate = false;
    request_save_consultor_rede.body = consultorPai;
    request_save_consultor_rede.cfg = {
      url: 'RedeDnaConsultor' + (!consultorPai.id || consultorPai.id === 0 ? '' : '/'+consultorPai.id),
      method: !consultorPai.id || consultorPai.id === 0 ? 'POST' : 'PUT',
      alert: false
    };
    request_save_consultor_rede.complete = (res) => {
      if (res.data) {
        setRedeDNA({ ...redeDNA });
        crud.refresh();
      }
      processaConsultoresExcluidos();
    };
    utils.call(request_save_consultor_rede);
  };

  const showAlert = function (msg) {
    let actions = [];
    actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {} });
    if (window.dialog) {
      window.dialog.alert(msg, null, actions);
    }
  };

  const removeConsultorClick = (node, parent = null) => {
    let actions = [];
    actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => { executaRemocaoConsultor(node, parent) } });
    actions.push({ label: utils.lng('cancelar', 'Cancelar'), action: () => {} });
    if (window.dialog) {
      window.dialog.alert(utils.lng('deseja_remover_consultor','Deseja realmente remover este Consultor?'), null, actions);
    }
  };

  const executaRemocaoConsultor = (node, parent = null) => {
    if (parent) {
      if (!listaConsultoresExcluidos) {
        listaConsultoresExcluidos = [];
      }
      if (node.id > 0) {
        listaConsultoresExcluidos.push(node);
        setListaConsultoresExcluidos(listaConsultoresExcluidos);
      }
      parent.filhos = parent.filhos.filter((child) => child.id !== node.id && child.consultor.id !== node.consultor.id);
      if (parent.id && parent.id > 0) {
        salvarRedeDnaConsultor(parent);
      }
      setRedeDNA({ ...redeDNA });
      crud.refresh();
    } else if (redeDNA && redeDNA.redeDnaConsultorOrigem && redeDNA.redeDnaConsultorOrigem.id === node.id && redeDNA.redeDnaConsultorOrigem.consultor.id === node.consultor.id) {
      if (redeDNA.id && redeDNA.id > 0) {
        deletaConsultorOrigem(false, (res) => {
          if (res.data) {
            reload(redeDNA.id);
          }
        });
      } else {
        redeDNA.redeDnaConsultorOrigem = null;
      }
    }
  };

  const processaConsultoresExcluidos = function () {
    if (listaConsultoresExcluidos && listaConsultoresExcluidos.length > 0) {
      for (let i = 0; i < listaConsultoresExcluidos.length; i++) {
        let consultorExcluido = listaConsultoresExcluidos[i];
        let isLast = i === (listaConsultoresExcluidos.length - 1);
        executaRequestExclusao(consultorExcluido, isLast);
      }
    } else {
      reload(redeDNA.id);
    }
  };

  const executaRequestExclusao = function(consultorExcluido, isLast) {
    const request_del_consultor_rede = {};
    
    request_del_consultor_rede.crud = crud;
    request_del_consultor_rede.validate = false;
    request_del_consultor_rede.cfg = {
      url: 'RedeDnaConsultor/' + consultorExcluido.id,
      method: 'DELETE',
      alert: false
    };
    if (isLast) {
      request_del_consultor_rede.complete = (res) => {
        if (res.data) {
          listaConsultoresExcluidos = [];
          setListaConsultoresExcluidos(listaConsultoresExcluidos);
          reload(redeDNA.id);
        }
      };
    }
    view.loadingStart();
    utils.call(request_del_consultor_rede);
  };

  const deletaConsultorOrigem = function() {
    const request_del_consultor_origem = {};
        
    request_del_consultor_origem.crud = crud;
    request_del_consultor_origem.validate = false;
    request_del_consultor_origem.cfg = {
      url: 'RedeDna/consultorOrigem/' + redeDNA.id,
      method: 'DELETE',
      alert: false
    };
    request_del_consultor_origem.complete = (res) => {
      if (res.data) {
        reload(redeDNA.id);
      }
    };
    utils.call(request_del_consultor_origem);
  };

  //

  const excluirRedeDNA = function() {
    let actions = [];
    actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => { executaRequestExclusaoRedeDNA()} });
    actions.push({ label: utils.lng('cancelar', 'Cancelar'), action: () => {} });
    if (window.dialog) {
      window.dialog.alert(utils.lng('confirm_exclusao_rede_dna','Deseja realmente excluir esta rede DNA?'), null, actions);
    }
  }

  const executaRequestExclusaoRedeDNA = function() {
    const request_del_rede = {};
    
    request_del_rede.crud = crud;
    request_del_rede.validate = false;
    request_del_rede.cfg = {
      url: 'RedeDna/' + redeDNA.id,
      method: 'DELETE',
      alert: true
    };
    request_del_rede.complete = (res) => {
      if (res.data) {
        reload();
      }
    };
    view.loadingStart();
    utils.call(request_del_rede);
  };


  const reload = function(idReload) {
    window.location.href = '/rede_dna'+(idReload ? '/'+idReload : '');
  }

  return (
    <View view={view} name="rede_dna">
      <Dialog
        // className="ui-dialog-header"
        open={showDialogSelecaoConsultor}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth={false}
        PaperProps={{ style: { width: '70%', height: '75%' } }}
      >
        <DialogTitle id="alert-dialog-title">{utils.lng('consultores','Consultores')}</DialogTitle>

        <DialogContent>
          <Element crud={{ changed: {}, selecionaConsultor: crud.selecionaConsultor }} view={view} value={listaConsultoresFilhos}></Element>
        </DialogContent>
        <DialogActions>
          <Button id="btn_fechar" onClick={switchShowDialogConsultor} color="primary">{utils.lng('fechar','Fechar')}</Button>
        </DialogActions>
      </Dialog>
      
      {idRedeDNA && idRedeDNA >= 0 &&
        <>
          <Element value={edit} crud={crud} data={crud.data} view={view} ></Element>

          <div className="ui-grid-controls-middle" style={{ marginTop: "20px" }}>
            <div className="ui-grid-controls-middle" style={{ height: "650px", overflow: 'auto' }}>
              <>
                {redeDNA && redeDNA.redeDnaConsultorOrigem &&
                  <>
                    <div className="rede_dna-tree-container">
                      <RedeDNATreeNode node={redeDNA.redeDnaConsultorOrigem} parent={null} onAdd={addConsultorClick} onRemove={removeConsultorClick} />
                    </div>
                  </>
                }
                {redeDNA && !redeDNA.redeDnaConsultorOrigem &&
                  <>
                    <div className="rede_dna-tree-container">
                      <CardConsultorOrigem node={redeDNA.redeDnaConsultorOrigem} parent={null} onAdd={addConsultorClick} onRemove={removeConsultorClick} />
                    </div>
                  </>
                }
              </>
            </div>
          </div>

          <div className="ui-col ui-col-12">
            <div className="ui-grid-controls-right ui-grid-controls-middle">
              <Button onClick={ () => { voltarListagem() } } variant="contained" color="primary" style={{marginRight: '15px', height: '45px'}}>
                { utils.lng('fechar', 'Fechar') }
              </Button>
              {redeDNA && redeDNA.id > 0 &&
                <Button onClick={ excluirRedeDNA } variant="contained" color="secondary" style={{marginRight: '15px', height: '45px'}}>
                  { utils.lng('excluir', 'Excluir') }
                </Button>
              }
              <Button onClick={ salvarRedeDNA } variant="contained" color="primary" style={{marginRight: '15px', height: '45px'}}>
                { utils.lng('salvar', 'Salvar') }
              </Button>
            </div>
          </div>
        </>
      }
      {!idRedeDNA &&
        <>
          <Element value={list} crud={crud} data={crud.data} view={view} ></Element>
        </>
      }
    </View>
  );
}
export default CrudRedeDNA;
