import React, { useState, useEffect } from "react";
import DateFnsUtils from '@date-io/date-fns';
import View from './view';
import utils from './utils';
import Chart from './chart';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Element from './element';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ptLocale from "date-fns/locale/pt-BR";
import { format } from 'date-fns';

function Dashboard() {
  const [view, setView] = useState({});
  const [comissao, setComissao] = useState({});
  const [data, setData] = useState({});
  const [resumo, setResumo] = useState({
    //cabeçalho
    qualificado: false,
    total_pontos: 0,
    valor_creditos: utils.currencySymbol()+' 0,00',
    graduacao: utils.lng('sem_graduacao','Sem graduação'),
    //quadro 1
    ponto_pessoal_acumulado: 0,
    ponto_rede_acumulado: 0,
    situacao: utils.lng('indefinido','Indefinido'),
    total_afiliados: 0,
    visualizar_spv_apurado: false
  });
  const [crud, setCrud] = useState({ selected: {}, data: {}, changed: {} });
  const [value, setValue] = useState({});
  const [ready, setReady] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogComissao, setOpenDialogComissao] = useState(false);
  const [index, setIndex] = useState(0);
  const [showSpv, setshowSpv] = useState(false);
  
  view.refresh = function () {
    setIndex(index + 1);
  }

  if (!ready) {
    setReady(true);

    let agora = new Date();
    let dataUtils = new DateFnsUtils(ptLocale);
    let dataInicial = dataUtils.startOfMonth(agora);
    let dataFinal = dataUtils.endOfMonth(agora);

    crud.changed.date_start = dataInicial.getTime();
    crud.changed.date_end = dataFinal.getTime();

    crud.selected.date_start = dataInicial.getTime();
    crud.selected.date_end = dataFinal.getTime();

    crud.data.date_start = dataInicial.getTime();
    crud.data.date_end = dataFinal.getTime();
    
    let dateStartIso = format( dataInicial, "yyyy-MM-dd'T'HH:mm:ss" );
    let dateEndIso = format( dataFinal, "yyyy-MM-dd'T'HH:mm:ss" );
    
    if (dateStartIso && dateStartIso !== '') {
      crud.changed.date_start_iso = dateStartIso;
      crud.selected.date_start_iso = dateStartIso;
      crud.data.date_start_iso = dateStartIso;
    }
    if (dateEndIso && dateEndIso !== '') {
      crud.changed.date_end_iso = dateEndIso;
      crud.selected.date_end_iso = dateEndIso;
      crud.data.date_end_iso = dateEndIso;
    }

    crud.sendChanged = true;

    resumo.imagem_graduacao = "https://cdn.conecteme.com.vc/files/utilizador_0000000001/58cfdfd769723568f812697fdba76923.png";

    utils.execute(
      crud, "dashboard_encontrarResumoDashboard?count=false", null,
      function (res) {
        if (res.data) {
          const a = {};

          for (const i in resumo) {
            a[i] = resumo[i];
          }

          for (const i in res.data) {
            const v = res.data[i];

            for (const vi in v) {
              a[vi] = v[vi];
            }
            setResumo(a);
          }
        }
      }
    );
  }

  let filtros = {
    dashboard_listarMeusPontos: "MEUS PONTOS",
    dashboard_listarPontosTime: "PONTOS EQUIPE",
    dashboard_listarRankingTime: "RANKING EQUIPE",
    dashboard_listarPontosNovosMembros: "NOVOS MEMBROS"
  };

  let periodos = resumo.visualizar_spv_apurado ? {
    mes_atual: "mês atual",
    mes_anterior: "mês anterior",
    trimestre: "trimestre",
    outros: "outros"
  } : {
    mes_atual: "mês atual"
  };

  let comissoes = {
    dashboard_listarComissaoGeral: "GERAL",
    dashboard_listarComissaoCredito: "CRÉDITO",
    dashboard_listarComissaoDebito: "DEBITO",
    dashboard_listarComissaoSPVPago: "SPV PAGO"
  };

  // let eventos = {
  //   mes_atual: "Mês atual",
  //   outros: "Outros"
  // };

  let periodosPontosErp = {
    mes_atual: "mês atual",
    mes_anterior: "mês anterior"
  };

  let gridPontosERP = {
    type: 'list',
    header: true,
    controls: false,
    search: false,
    list: {
      url: 'dashboard_listarPontosERP?count=false'
    },
    columns: {
      nome_pais: { bold: true, format: 'text', space: 6,  label: utils.lng('pais', "Pais"), filter: false, sort: false, style: { fontSize: '20px' } },
      pontos_individuais: { field: 'pontos_individuais', bold: true, space: 2, label: utils.lng('pontos_individuais', "Pontos Individuais"), filter: false, sort: false },
      pontos_rede: { field: 'pontos_rede', bold: true, space: 2, label: utils.lng('pontos_rede', "Pontos Rede"), filter: false, sort: false },
      total_pontos: { field: 'total_pontos', bold: true, space: 2, label: utils.lng('total_por_unidade', "Total por Unidade"), filter: false, sort: false, style: { float: 'right' } }
    }
  };

  let gridComissao = {
    type: 'list',
    header: false,
    controls: false,
    search: false,
    list: {
      url: 'dashboard_listarComissaoGeral'
    },
    columns: {
      descricao: {},
      valor: { bold: true, format: 'money', style: { float: 'right' } },
      ver: {
        type: 'button',
        label: 'ver',
        click: {
          action: 'detalheComissao'
        }
      }
    }
  };

  let gridDetalheComissao = {
    type: 'list',
    add: false,
    rows: 5,
    list: {
      url: 'dashboard_listarComissaoDetalhePedidoPorCategoria'
    },
    columns: {
      numero_pedido: { label: utils.lng('pedido','Pedido') },
      data_movto: { label: utils.lng('data','Data'), type: 'date' },
      nome_cliente: { label: utils.lng('cliente','Cliente') },
      nome_filial: { label: utils.lng('filial','Filial') },
      valor_total: { label: utils.lng('vlr_total','Vlr. Total'), bold: true, format: 'money', style: { float: 'right' } },
      valor: { label: utils.lng('vlr_spv','Vlr. SPV'), bold: true, format: 'money', style: { float: 'right' } }
    }
  };

  let gridNoticias = {
    type: 'list',
    add: false, header: false, search: false, controls: false,
    layout: 'card-row',
    style: { textAlign: 'left', paddingLeft: '20px' },
    size: 3,
    list: {
      url: 'feed?count=false'
    },
    elements: {
      cardEvt: {
        space: 12,
        style: { cursor: 'pointer' },
        click: { redirect: '{link}' },
        layout: 'grid',
        elements: {
          image: {
            type: 'img', rootStyle: { width: '40px' },
            style: { borderRadius: '0px', marginRight: '20px', width: '30px' },
            value: 'https://icons.iconarchive.com/icons/designcontest/ecommerce-business/96/news-icon.png'
          },
          info: {
            style: { verticalAlign: 'middle' },
            elements: {
              title: { space: 12, bold: true, style: { cursor: 'pointer' } },
              description: { space: 12, style: { cursor: 'pointer' } }
            }
          }
        }
      }
    }
  };

  let gridPontos = {
    type: 'list',
    header: false,
    controls: true,
    search: false,
    list: {
      url: 'dashboard_listarMeusPontos'
    },
    columns: {
      posicao: {},
      avatar: {},
      nome: {},
      pontuacao: {
        value: '${@this.pontos +" / "+@this.pontos_rede}',
        bold: true,
        style: {
          float: 'right'
        }
      }
    }
  };

  let gridCalendario = {
    type: 'list',
    header: false,
    controls: false,
    search: false,
    rowStyleClass: "${'ui-evt-' + ('' + @this.periodo).toLowerCase()}",
    list: {
      url: 'listarEventos?count=false'
    },
    columns: {
      titulo: {},
      data_inicial: { type: 'datetime' }
    },
    click: {
      redirect: '/crud/evento/{id}'
    }
  };

  let series = {
    key: 'ano_mes',
    value: 'total',
    group: 'grupo'
  };

  let labels = {
    time: utils.lng('equipe','Equipe'),
    pessoal: utils.lng('pessoal','Pessoal')
  };

  crud.showCalendarDialog = function (onChangeDate) {
    crud.onChangeDate = onChangeDate;
    setOpenDialog(true);
  };

  crud.detalheComissao = function (el, args) {
    setComissao(args.data);
    crud.changed.id_categoria_bonus = args.data.id;
    setOpenDialogComissao(true);
  };

  const handleCloseDialog = function () {
    if (crud.changed.date_start && crud.changed.date_end) {
      if (crud.onChangeDate) {
        crud.onChangeDate.call(this);
      }
      setOpenDialog(false);
    }
  };

  const cancelPeriodDialog = function () {
    setOpenDialog(false);
  };

  const handleCloseDialogComissao = function () {
    setOpenDialogComissao(false);
  };

  const dates = {
    name: 'dates',
    elements: {
      date_start: {
        type: 'date',
        dateFormat: 'long',
        label: utils.lng('data_inicial','Data inicial')
      }, date_end: {
        type: 'date',
        dateFormat: 'long',
        label: utils.lng("data_final","Data final")
      }
    }
  };


  const showItem = function (o) {
    return o.pontos > 0;
  }

  const atualizaSaldoPontosClienteERP = function() {
    view.loadingStart();
    utils.execute(
      crud, "atualizaSaldoPontosClienteERP?count=false", null,
      function (res) {
        if (res.data && res.data.saldo_atualizado === true) {
          window.location.href = '/dashboard';
        }
      }
    );
  }


  return (
    <View view={view}>
      <>
        <Dialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            {utils.lng('select_period', 'Selecione um periodo')}
          </DialogTitle>
          <DialogContent>
            <Element crud={crud} value={dates} data={crud.data} />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={cancelPeriodDialog} color="primary">
              {utils.lng('cancelar', 'Cancelar')}
            </Button>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              {utils.lng('confirmar', 'Confirmar')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog maxWidth="lg" onClose={handleCloseDialogComissao} aria-labelledby="customized-dialog-title" open={openDialogComissao}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialogComissao}>
            {utils.lng('dashboard_detail_comissao', 'Detalhamento de comissão')}
          </DialogTitle>
          <DialogContent>
            <Element crud={crud} value={gridDetalheComissao} data={crud.data} />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialogComissao} color="primary">
              {utils.lng('confirmar', 'Confirmar')}
            </Button>
          </DialogActions>
        </Dialog>
        <div className="ui-topo-dashboard ui-shadow ui-border ui-white">
          <div className="ui-col ui-col-12 ui-padding ui-bold ">
            {utils.lng('boas_vindas', 'Boas-vindas')+', '+(resumo && resumo.nome_cliente ? resumo.nome_cliente : '')}
          </div>
        </div>
        {
        utils.getPaisUser() === "BRA" &&
        <>

        <div className="ui-grid ui-dashboard ui-dashboard-class">
          <Chart space="12" crud={crud}>
            <Chart crud={crud} id="ch8" height="170px" space="12" type="container">
              <div>
                <div className="ui-bold">
                  <div className="ui-dashboard-resumo-row">
                    <div id="col_ponto_pessoal_acumulado" className="ui-dashboard-resumo-col">
                      <div id="ponto_pessoal_acumulado" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue-dark">
                        {resumo.exibirPontos &&
                          <>
                            {resumo.ponto_pessoal_acumulado}
                          </>
                        }
                        {!resumo.exibirPontos &&
                          <>
                            {'-----------'}
                          </>
                        }
                      </div>
                      <div className=".ui-dashboard-resumo-label-col">
                        <i className="fas fa-user" style={{marginLeft: '10px'}}></i>
                        <span className="ui-totais-label">{utils.lng('pontos_individuais_acumulados_mes', 'Pontos individuais acumulados no mês')}</span>
                      </div>
                    </div>

                    <div id="resumo_qualificacao" className="ui-dashboard-resumo-col">
                      <div id="qualificacao" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue-dark">
                        {resumo.qualificado &&
                          <>
                            <span className="ui-chart-destaque">
                              <i className="fa fa-star"></i>
                            </span>
                          </>
                        }
                        {!resumo.qualificado &&
                          <>
                            <span className="ui-chart-destaque">
                              <i className="fa fa-times"></i>
                            </span>
                          </>
                        }
                      </div>
                      <div className="ui-dashboard-resumo-label-col">
                        {resumo.qualificado &&
                          <>
                            {utils.lng('qualificado_para_spv', 'Qualificado para SPV')}
                          </>
                        }
                        {!resumo.qualificado &&
                          <>
                            <span className="ui-chart-destaque">
                              {utils.lng('nao_qualificado_para_spv', 'Não qualificado para SPV')}
                            </span>
                          </>
                        }
                        <div className="ui-dashboard-resumo-showspv">
                          {showSpv ? (
                            <button className="btn-eye" onClick={() => setshowSpv(false)}>
                              <i className="far fa-eye-slash" id="showspv"></i>
                            </button>
                          ) : (
                            <button className="btn-eye" onClick={() => setshowSpv(true)}>
                              <i className="far fa-eye" id="notshowspv"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ui-dashboard-resumo-row">
                    <div className="ui-dashboard-resumo-col">
                      <div className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue">
                        {resumo.exibirPontos &&
                          <>
                            {resumo.ponto_rede_acumulado}
                          </>
                        }
                        {!resumo.exibirPontos &&
                          <>
                            {'-----------'}
                          </>
                        }
                      </div>
                    
                      <div className="ui-dashboard-resumo-label-col">
                        <i className="fas fa-users"></i>
                        <span className="ui-totais-label">{utils.lng('pontos_equipe_acumulados_mes', 'Pontos de equipe acumulados no mês')}</span>
                      </div>
                    </div>

                    <div className="ui-dashboard-resumo-col">
                      <div id="ponto_rede_acumulado" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue">
                        {showSpv &&
                          <>
                            {utils.money(resumo.valor_creditos)}
                          </>
                        }
                        {!showSpv &&
                          <>
                            {utils.lng("currency_symbol","R$")+"-----------"}
                          </>
                        }
                      </div>
                    
                      <div className="ui-dashboard-resumo-label-col">
                        <div className="ui-grid-col">
                          {utils.lng('previa_spv_mes_atual', 'Prévia SPV mês atual')}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="ui-dashboard-resumo-row">
                    <div id="col_ponto_acumulado_trimestre" className="ui-dashboard-resumo-col">
                      <div id="ponto_acumulado_trimestre" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue-dark">
                        {resumo.exibirPontos &&
                          <>
                            {resumo.pontos_individuais_ultimo_trimestre}
                          </>
                        }
                        {!resumo.exibirPontos &&
                          <>
                            {'-----------'}
                          </>
                        }
                      </div>
                      <div className="ui-dashboard-resumo-label-col">
                        <i className="fas fa-user"></i>
                        <span className="ui-totais-label">{utils.lng('pontos_individuais_acumulados_trimestre', 'Pontos individuais acumulados no trimestre')}</span>
                      </div>
                    </div>

                    <div id="col_saldo_spv" className="ui-dashboard-resumo-col">
                      <div id="saldo_spv" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue-dark">
                        {showSpv &&
                          <>
                            {utils.money(resumo.saldo_spv)}
                          </>
                        }
                        {!showSpv &&
                          <>
                            {utils.lng("currency_symbol","R$")+"-----------"}
                          </>
                        }
                      </div>
                      <div className="ui-dashboard-resumo-label-col">
                        {utils.lng('saldo_spv', 'Saldo SPV')}
                      </div>
                    </div>
                  </div>

                  <div className="ui-dashboard-resumo-row">
                    <div id="col_qtde_filiados" className="ui-dashboard-resumo-col">
                      <div id="qtde_filiados" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue">
                        {resumo.total_afiliados}
                      </div>
                      <div className="ui-dashboard-resumo-label-col">
                        <i className="fas fa-globe-americas"></i>
                        <span className="ui-totais-label">{utils.lng('filiados', 'Filiados')}</span>
                      </div>
                    </div>

                    <div id="col_outros_saldos" className="ui-dashboard-resumo-col">
                      <div id="outros_saldos" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue">
                        {showSpv &&
                          <>
                            {utils.money(resumo.saldo_outros)}
                          </>
                        }
                        {!showSpv &&
                          <>
                            {utils.lng("currency_symbol","R$")+"-----------"}
                          </>
                        }
                      </div>
                      <div className="ui-dashboard-resumo-label-col">
                        {utils.lng('outros_saldos', 'Outros Saldos')}
                      </div>
                    </div>
                  </div>

                  <div className="ui-dashboard-resumo-row">
                    <div id="col_periodo" className="ui-dashboard-resumo-col">
                      <div id="periodo" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue-dark">
                        {resumo.periodo_referencia}
                      </div>
                      <div className="ui-dashboard-resumo-label-col">
                        <i className="fas fa-calendar"></i>
                        <span className="ui-totais-label">{utils.lng('periodo', 'Periodo')}</span>
                      </div>
                    </div>

                    <div id="col_graduacao" className="ui-dashboard-resumo-col">
                      <div id="graduacao" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue-dark">
                        <img src={resumo.imagem_graduacao} style={{ height: '45px' }}></img>
                      </div>
                      <div className="ui-dashboard-resumo-label-col">{resumo.graduacao}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Chart>
          </Chart>
          
          {resumo.exibir_pontos_erp &&
            <>
              <Chart crud={crud} id="chPtsErp" space="6" type="grid" title={utils.lng('pontos_erp', "Pontos ERP")} source={gridPontosERP} period="mes_atual" periods={periodosPontosErp} action="dashboard_api_listarPontosERP?count=false" ></Chart>
            </>
          }

          {resumo.exibirPontos &&
            <>
              <Chart crud={crud} id="ch3" space="6" type="bar" title={utils.lng('vendas_pontos', "Vendas / Pontos")} series={series} labels={labels} period="quatro_meses" action="dashboard_listarPontoVendas"></Chart>
            </>
          }

          <Chart crud={crud} id="ch1" height="265px" space="6" title={utils.lng('pontos_por_pais', "Pontos / Países")} type="bar" layout="horizontal" action="dashboard_listarPontoPorPais?count=false"></Chart>
          
          {resumo.exibirPontos &&
            <>
              <Chart crud={crud} id="ch4" space="6" type="grid" title={utils.lng('comissoes_estornos', "SPV e estornos")} source={gridComissao} period="mes_atual" periods={periodos} action="dashboard_listarComissaoGeral?count=false" filters={comissoes} ></Chart>
              <Chart crud={crud} id="ch5" space="6" type="grid" title={utils.lng('pontos_performance', "Pontos / Performance")} source={gridPontos} period="mes_atual" periods={periodos} action="dashboard_listarMeusPontos?count=false" filters={filtros} ></Chart>
              <Chart crud={crud} id="ch2" space="6" type="pie" title={utils.lng('pontos_linha_produto', "Pontos por linha de produto")} action="dashboard_listarPontoPorLinhaProduto?count=false" ></Chart>
            </>
          }
          
          <Chart crud={crud} space="6" title={utils.lng('distribuicao_percentual_filiacao_pais', "Distribuição Percentual de filiados por País")} height="460px" >
            <Chart crud={crud} id="ch6" space="6" type="map" action="dashboard_listarFiliacao?count=false"></Chart>
            <Chart crud={crud} id="ch7" space="6" type="pie" layout="horizontal" isPercentual="true" extendColorPalete={true} action="dashboard_listarFiliacao?count=false"></Chart>
          </Chart>
          {resumo.exibirPontos &&
            <Chart itemLevel="recursion_level" size="-1" rendered={showItem} randomColors={true} crud={crud} id="ch10" space="12" type="sunburst"
              recursive={false} fieldKey="id" parentFieldKey="id_superior" height="800px"
              title={utils.lng('mapa_pontos', "Mapa de pontos")} action="listarMapaPontos?count=false" parent={{ id: 'superior' }}
              itemKey="id" itemLabel="nome" itemValue="pontos" itemParent="id_superior" tooltip="{nome}, Pontos: {pontos}" ></Chart>
          }
          {/* <Chart crud={crud} id="ch8" space="12" type="grid" title={utils.lng('eventos', "Eventos")} source={gridCalendario} action="dashboard_listarEventosMes?count=false" period="mes_atual" periods={eventos} ></Chart> */}
          {/* <Chart crud={crud} id="ch9" space="12" height="440px" type="grid" title={utils.lng('noticias', "Noticias")} source={gridNoticias} styleClass="ui-chart-noticias" ></Chart> */}
        </div>

        </>
        }

        {utils.getPaisUser() !== "BRA" &&
          <>
            <div className="ui-grid ui-dashboard ui-dashboard-class">
              <Chart space="12" crud={crud}>
                <Chart crud={crud} id="ch8" height="170px" space="12" type="container">
                  <div>
                    <div className="ui-bold">
                      <div className="ui-dashboard-resumo-row">
                        <div id="col_ponto_pessoal_acumulado" className="ui-dashboard-resumo-col">
                          <div id="ponto_pessoal_acumulado" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue-dark">
                            { resumo.exibirPontos ? resumo.total_pontos_erp ? resumo.total_pontos_erp : 0.0 : '-----------' }
                          </div>
                          <div className=".ui-dashboard-resumo-label-col">
                            <i className="fas fa-users" style={{marginLeft: '10px'}}></i>
                            <span className="ui-totais-label">{utils.lng('pontuacao_global_total', 'Pontuação Global Total')}</span>
                          </div>
                        </div>

                        <div id="col_qtde_filiados" className="ui-dashboard-resumo-col">
                          <div id="qtde_filiados" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue-dark">
                            {resumo.total_afiliados}
                          </div>
                          <div className="ui-dashboard-resumo-label-col">
                            <i className="fas fa-globe-americas"></i>
                            <span className="ui-totais-label">{utils.lng('afiliados_diretos', 'Afiliados Diretos')}</span>
                          </div>
                          <button className="btn-eye" onClick={() => atualizaSaldoPontosClienteERP()}>
                            <i className="fa fa-undo" id="refresh_pts_erp"></i>
                          </button>
                        </div>
                      </div>

                      <div className="ui-dashboard-resumo-row">
                        <div id="col_periodo" className="ui-dashboard-resumo-col">
                          <div id="periodo" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue">
                            {resumo.periodo_referencia}
                          </div>
                          <div className="ui-dashboard-resumo-label-col">
                            <i className="fas fa-calendar"></i>
                            <span className="ui-totais-label">{utils.lng('periodo', 'Período')}</span>
                          </div>
                        </div>

                        <div id="col_graduacao" className="ui-dashboard-resumo-col">
                          <div id="graduacao" className="ui-dashboard-resumo-value-col ui-dashboard-bg-blue">
                            <img src={resumo.imagem_graduacao} style={{ height: '45px' }}></img>
                          </div>
                          <div className="ui-dashboard-resumo-label-col">{resumo.graduacao}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Chart>
              </Chart>

              <Chart crud={crud} id="chPtsErpExterior" height="460px" space="6" type="grid" title={utils.lng('pontos_erp', "Pontos ERP")} source={gridPontosERP} period="mes_atual" periods={periodosPontosErp} ></Chart>
              
              <Chart crud={crud} space="6" title={utils.lng('pontuacao_percentual_unidade', "Pontuação Percentual por Unidade")} height="460px" >
                <Chart crud={crud} id="chFiliacaoPaisMap" space="6" type="map" action="dashboard_listarPercentualPontuacaoPorUnidade?is_map=true&count=false"></Chart>
                <Chart crud={crud} id="chFiliacaoPaisPie" space="6" type="pie" action="dashboard_listarPercentualPontuacaoPorUnidade?count=false" isPercentual="true" extendColorPalete={true}></Chart>
              </Chart>
              
              <Chart crud={crud} id="chPontosVendas" space="6" height="460px" type="bar" title={utils.lng('pontos_individual_equipe', "Pontos Individuais / Equipe")} series={series} labels={ { time: utils.lng('equipe','Equipe'), pessoal: utils.lng('individual','Individual') } } period="quatro_meses" action="dashboard_listarPontoERPVendas?count=false"></Chart>

              <Chart crud={crud} id="chPontosPaisBarras" height="460px" space="6" title={utils.lng('pontos_totais_por_unidade', "Pontos Totais por Unidade")} type="bar" layout="horizontal" action="dashboard_listarPontosErpPorUnidade?count=false"></Chart>
              
            </div>

          </>
        } 
      </>
    </View>
  );
}

export default Dashboard;