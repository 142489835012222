import React, { useEffect, useState } from "react";
import View from './view';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Element from './element';
import utils from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheck } from "@fortawesome/free-solid-svg-icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';


function ClonagemCliente() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let [showDialogSelecaoCliente, setShowDialogSelecaoCliente] = useState(false);

  let [clienteCopiar, setClienteCopiar] = useState(null);
  let [filialDestino, setFilialDestino] = useState(null);

  let [isRedeProjetada, setIsRedeProjetada] = useState(false);
  
  const user = utils.getUser();
  
  let [idClienteClonar, setIdClienteClonar] = useState(null);
  let [idFilialDestino, setIdFilialDestino] = useState(null);

  let urlParts = window.location.pathname.substring(1).split('/');

  let [listaRedeProjetadaClonagem, setListaRedeProjetadaClonagem] = useState([]);

  crud.onChangeCliente = function(el, args) {
    clienteCopiar = args.cliente_copiar;
    setClienteCopiar(clienteCopiar);
    if (clienteCopiar && clienteCopiar.id > 0) {
      changeIsRedeProjetada();
    }
  };

  crud.onChangeFilial = function(el, args) {
    filialDestino = args.filial_destino;
    setFilialDestino(filialDestino);
    if (filialDestino && filialDestino.id > 0) {
      idFilialDestino = filialDestino.id;
      setIdFilialDestino(idFilialDestino);
      changeIsRedeProjetada();
    } else {
      idFilialDestino = null;
      setIdFilialDestino(idFilialDestino);
    }
  };

  crud.limpaTela = function() {
    resetVars();
  };

  const resetVars = function() {
    clienteCopiar = null;
    filialDestino = null;
    listaRedeProjetadaClonagem = [];
    setClienteCopiar(clienteCopiar);
    crud.elements.cliente_copiar.setValue(clienteCopiar);
    setFilialDestino(filialDestino);
    crud.elements.filial_destino.setValue(filialDestino);
    setListaRedeProjetadaClonagem(listaRedeProjetadaClonagem);
    changeIsRedeProjetada();
  };

  crud.requestProjecaoRede = function() {
    if (clienteCopiar && filialDestino) {
      const request_projecao_rede = {};
    
      request_projecao_rede.crud = crud;
      request_projecao_rede.validate = false;
      request_projecao_rede.changed = crud.data;
      request_projecao_rede.element = {};

      request_projecao_rede.cfg = {
        url: 'copia/cliente/projecao_rede?id_cliente='+ clienteCopiar.id+'&id_filial_destino='+filialDestino.id,
        method: 'GET'
      };
      request_projecao_rede.complete = (res) => {
        if (res.data && res.data.length > 0) {
          listaRedeProjetadaClonagem = res.data;
          setListaRedeProjetadaClonagem(listaRedeProjetadaClonagem);
          changeIsRedeProjetada();
        }
      };
      utils.call(request_projecao_rede);
    }
  };

  const changeIsRedeProjetada = function() {
    isRedeProjetada = clienteCopiar != null &&
           filialDestino != null &&
           (listaRedeProjetadaClonagem && listaRedeProjetadaClonagem != null && listaRedeProjetadaClonagem.length > 0);
    setIsRedeProjetada(isRedeProjetada);
    return isRedeProjetada;
  };

  const loadClienteClonar = function(idClienteClonar) {
    if (idClienteClonar && idClienteClonar > 0) {
      utils.execute(crud, 'Cliente/' + idClienteClonar, null, function (res) {
        if (res.data && res.data.id > 0) {
          clienteCopiar = res.data;
          setClienteCopiar(clienteCopiar);
          crud.elements.cliente_copiar.setValue(clienteCopiar);
          if (clienteCopiar && clienteCopiar.id > 0 && filialDestino && filialDestino.id > 0) {
            crud.requestProjecaoRede();
          }
        }
      }, true);
    }
  };

  const loadFilialDestino = function (idFilialDestino) {
    if (idFilialDestino && idFilialDestino > 0) {
      utils.execute(crud, 'Filial/' + idFilialDestino, null, function (res) {
        if (res.data && res.data.id > 0) {
          filialDestino = res.data;
          setFilialDestino(filialDestino);
          crud.elements.filial_destino.setValue(filialDestino);
          if (clienteCopiar && clienteCopiar.id > 0 && filialDestino && filialDestino.id > 0) {
            crud.requestProjecaoRede();
          }
        }
      }, true);
    }
  };

  
  useEffect(() => {
    if (!ready) {
      setReady(true);

      if (urlParts.length > 1) {
        idClienteClonar = urlParts[1];
        if (idClienteClonar && idClienteClonar > 1) {
          setIdClienteClonar(idClienteClonar);
          loadClienteClonar(idClienteClonar);
        }
        try {
          idFilialDestino = urlParts[2];
          if (idFilialDestino && idFilialDestino > 1) {
            setIdFilialDestino(idFilialDestino);
            loadFilialDestino(idFilialDestino);
          }
        } catch (e) {}
      }
    }
  });
  
  
  const confirmarClonagemRede = function () {
    if (!isRedeProjetada) {
      let actions = [];
      actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {} });
      if (window.dialog) {
        utils.lng('faltam_informacoes_efetivar_clonagem', 'Faltam informações para efetivar a clonagem da rede, Verifique!');
      }
    } else {
      let msgValidacao = validaListaClientes(listaRedeProjetadaClonagem);
      if (msgValidacao === '') {
        let actions = [];
        actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {gravaRedeClonada()} });
        actions.push({ label: utils.lng('cancelar', 'Cancelar'), action: () => {} });
        if (window.dialog) {
          window.dialog.alert(utils.lng('confirm_clonagem_rede','Deseja realmente clonar os cadastros selecionados?'), null, actions);
        }
      } else {
        let actions = [];
        actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {} });
        if (window.dialog) {
          window.dialog.alert(msgValidacao, null, actions);
        }
      }
    }
  };

  const validaListaClientes = function(listaClientes) {
    let msgValidacao = validaPresencaFilialDestino(listaClientes);
    if (!msgValidacao || msgValidacao === '') {
      msgValidacao = validaDocumentos(listaClientes);
    }
    return msgValidacao;
  };

  const validaPresencaFilialDestino = function(listaClientes) {
    let hasAlgumClienteNovo = false;
    for (let i=0; i<listaClientes.length; i++) {
      let cliente = listaClientes[i];
      if (cliente) {
        hasAlgumClienteNovo = cliente.isNovoCadastro === true;
        if (hasAlgumClienteNovo === true) {
          break;
        }
      }
    }
    let msg = hasAlgumClienteNovo ? '' : utils.lng(
      'todos_clientes_existem_filial_destino', 
      'Todos os Clientes da rede projetada já existem na Filial de Destino. Verifique!'
    );
    return msg;
  };

  const validaDocumentos = function(listaClientes) {
    let todosDoctosPreenchidos = false;
    for (let i=0; i<listaClientes.length; i++) {
      let cliente = listaClientes[i];
      if (cliente) {
        todosDoctosPreenchidos = cliente.documentoNovoCadastro != '' && (cliente.documentoNovoCadastro && cliente.documentoNovoCadastro.length > 1);
        if (todosDoctosPreenchidos === false) {
          break;
        }
      }
    }
    return todosDoctosPreenchidos ? '' : utils.lng('todos_doctos_devem_ser_preenchidos', 'Todos os Documentos devem ser Preenchidos. Verifique!');
  };

  const gravaRedeClonada = function() {
    const request_post_rede = {};
        
    request_post_rede.crud = crud;
    request_post_rede.validate = false;
    request_post_rede.changed = crud.data;
    request_post_rede.element = {};
    request_post_rede.body = {"rede":listaRedeProjetadaClonagem};
    request_post_rede.cfg = {
      url: 'copia/cliente/rede_completa?id_cliente='+ clienteCopiar.id+'&id_filial_destino='+filialDestino.id,
      method: 'POST'
    };
    request_post_rede.complete = (res) => {
      if (res.data && res.data.length > 0) {
        let listaRedeClonada = res.data;
        window.location.href = "/clonagem_cliente/"+clienteCopiar.id+'/'+filialDestino.id;
        // listaRedeClonada.forEach((novoCliente) => {window.location.href = "/crud/Cliente/"+novoCliente.id});
      }
    };
    utils.call(request_post_rede);
  };

  //

  const switchDialogSelecaoClientes = function() {
    showDialogSelecaoCliente = !showDialogSelecaoCliente;
    setShowDialogSelecaoCliente(showDialogSelecaoCliente);
  };

  let [indiceListaClienteTrocar, setIndiceListaClienteTrocar] = useState(null);
  const abreSelecaoCliente = function(index, obj) {
    indiceListaClienteTrocar = index;
    setIndiceListaClienteTrocar(indiceListaClienteTrocar);
    switchDialogSelecaoClientes();
  };

  crud.selecionaCliente = function(el, item) {
    let clienteEscolhido = item ? item.data : null;
    if (clienteEscolhido && clienteEscolhido.id > 0) {
      let url = 'copia/cliente/dto_cliente_projecao_rede?id_cliente=' + clienteEscolhido.id + 
                '&id_filial_destino=' + idFilialDestino + 
                '&indice_ordem_geracional='+indiceListaClienteTrocar
      utils.execute(crud, url, null, function (res) {
        if (res.data && res.data.id > 0) {
          clienteEscolhido = res.data;
          let isClienteNaLista = listaRedeProjetadaClonagem.some(obj => obj.id === clienteEscolhido.id);
          if (isClienteNaLista) {
            let actions = [];
            actions.push({ label: utils.lng('fechar', 'Fechar'), action: () => {} });
            if (window.dialog) {
              window.dialog.alert(utils.lng('cliente_ja_esta_na_lista', 'O Cliente selecionado já está na lista. Verifique!'), null, actions);
            }
          } else {
            listaRedeProjetadaClonagem[indiceListaClienteTrocar] = clienteEscolhido;
            switchDialogSelecaoClientes();
          }
        }
      }, true);
    }
  };

  //

  const listaClientesFilialDestino = {
    elements: {
      content: {
        styleClass: 'ui-lista-clientes',
        elements: {
          lista_clientes_filial_destino: {
            name: 'lista_clientes_filial_destino',
            header: true,
            layout: 'clear',
            add: false,
            type: 'list',
            list: {
              url: 'listar_clientes_por_pais_filial?id_filial_destino='+idFilialDestino
            },
            click: {
              action: 'selecionaCliente'
            },
            columns: {
              codigo: { label: utils.lng("codigo","Código") },
              codigo_referencia: { label: utils.lng("codigo_erp","Código ERP") },
              nome: { label: utils.lng("nome","Nome") }
            }
          }
        }
      }
    }
  };

  const formStart = {
    label: utils.lng('clonagem_cliente_rede', 'Clonagem de Cliente/Rede'),
    name: 'clonagemCliente',
    add: false,
    print: false,
    auto: true,
    type: 'card',
    widgetVar: 'dadosClonagem',
    elements: {
      cliente_copiar: {
        type: 'entity',
        entity: 'Cliente',
        name: 'cliente_copiar',
        field: 'cliente_copiar',
        label: utils.lng('cliente', "Cliente"),
        required: true,
        space: 5,
        disabled: isRedeProjetada,
        data: clienteCopiar,
        change: {
          action: 'onChangeCliente'
        }
      },
      filial_destino: {
        type: 'entity',
        entity: 'Filial',
        name: 'filial_destino',
        field: 'filial_destino',
        label: utils.lng('filial_destino', "Filial de Destino"),
        required: true,
        space: 5,
        disabled: isRedeProjetada,
        data: filialDestino,
        change: {
          action: 'onChangeFilial'
        },
        list: {
          url: 'listar_todas_as_filiais_ativas',
          send: false
        }
      },
      btn_projetar_rede: {
        type: 'button',
        space: 1,
        label: utils.lng('projetar_rede', 'Projetar Rede'),
        disabled: !clienteCopiar || !filialDestino,
        click: {
          action: 'requestProjecaoRede'
        }
      },
      btn_limpar: {
        type: 'button',
        space: 1,
        label: utils.lng('limpar', 'Limpar'),
        click: {
          action: 'limpaTela'
        }
      }
    }
  };

  return (
    <View name="ui-clonagem-cliente">
      <div>
        <div className="ui-col ui-col-12">
          <Element crud={crud} view={view} value={formStart}></Element>
        </div>

        <Dialog
          className="ui-mmn-dialog"
          open={showDialogSelecaoCliente}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={false}
          maxWidth={false}
          PaperProps={{ style: { width: '60%', height: '60%' } }}
        >
          <DialogContent>
            <Element crud={crud} view={view} value={listaClientesFilialDestino}></Element>
          </DialogContent>
          <DialogActions>
            <Button id="btn_fechar" onClick={switchDialogSelecaoClientes} color="primary">{utils.lng('fechar','Fechar')}</Button>
          </DialogActions>
        </Dialog>
        
        {listaRedeProjetadaClonagem &&
          <>
            <div className="ui-grid-controls-middle">
              {listaRedeProjetadaClonagem.map((li, index) => (
                <div className={"clonagem-cliente-card-"+(li.isNovoCadastro === true ? "novo" : "existente")}>
                  <div className={"clonagem-cliente-card-border-"+(li.isNovoCadastro === true ? "novo" : "existente")}></div>
                  <div className={"clonagem-cliente-card-content-"+(li.isNovoCadastro === true ? "novo" : "existente")}>
                    <FontAwesomeIcon 
                      icon={li.isNovoCadastro === true ? faExclamationTriangle : faCheck} 
                      className={"clonagem-cliente-card-icon-"+(li.isNovoCadastro === true ? "novo" : "existente")} 
                      title={li.isNovoCadastro === true ? utils.lng('novo_cadastro','Novo Cadastro') : utils.lng('cadastro_ja_existente','Cadastro já existente')} 
                    />

                    <div className="clonagem-cliente-card-content-docto">
                      <TextField 
                        variant="outlined" 
                        required={true} 
                        label={utils.lng('documento', 'Documento')}
                        disabled={!li.isNovoCadastro} 
                        value={li.documentoNovoCadastro+''} 
                        type='text' 
                        inputProps={{ maxLength: 20 }}
                        style={{ margin: 5, display: 'block' }}
                        onChange={(e) => {
                          const updatedList = [...listaRedeProjetadaClonagem];
                          updatedList[index].documentoNovoCadastro = e.target.value;
                          setListaRedeProjetadaClonagem(updatedList);
                        }}
                      >
                      </TextField>
                    </div>

                    <div className={"clonagem-cliente-card-text-"+(li.isNovoCadastro === true ? "novo" : "existente")}>
                      
                      <h3>{ '(' + li.descricaoOrdemGeracional + ') ' + li.codigoMundial + ' - ' + li.nome }</h3>

                      <i class="fas fa-edit fa-2x" 
                        style={{ marginLeft : '20px', cursor : 'pointer' }} 
                        title={utils.lng('mudar_cliente', 'Mudar Cliente')}
                        onClick={(e) => {
                          abreSelecaoCliente(index, li);
                        }}
                      ></i>

                      {!li.isNovoCadastro &&
                        <i class="fas fa-book fa-2x" 
                          style={{ marginLeft : '20px', cursor : 'pointer' }} 
                          title={utils.lng('abrir_cadastro', 'Abrir Cadastro')}
                          onClick={(e) => {
                            window.location.href = '/crud/Cliente/'+li.id;
                          }}
                        ></i>
                      }

                    </div>
                    
                  </div>
                </div>
              ))}
            </div>
            
            <div className="ui-col ui-col-12">
              <div className="ui-grid-controls-right ui-grid-controls-middle">
                <Button onClick={confirmarClonagemRede} disabled={!isRedeProjetada} variant="contained" color="primary" style={{marginRight: '15px', height: '45px'}}>
                  {utils.lng('confirmar_clonagem_clientes', 'Confirmar dos Clonagem Clientes')}
                </Button>
              </div>
            </div>
          </>
        }
      </div>
    </View>
  )
}
export default ClonagemCliente;