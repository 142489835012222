import React, { useState } from "react";
import { isAuthenticated } from "./auth";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Generic from './components/generic';
import Report from './components/report';
import Login from './components/login';
import EsqueciSenha from './components/esqueci_senha';
import RecuperarSenha from './components/recuperar_senha';
import Home from './components/home';
import Classificacao from './components/classificacao';
import Carrinho from './components/carrinho';
import Noticias from './components/noticias';
import Favoritos from './components/favoritos';
import Informativos from './components/informativos';
import PagamentosPendente from './components/pagamentos_pendente';
import Pagamento from './components/pagamento';
import Produto from './components/produto';
import Pedido from './components/pedido';
import Swagger from './components/swagger';
import MeusDados from './components/meus_dados';
import MeusPedidos from "./components/meus_pedidos";
import Promocoes from "./components/promocoes";
import SqlExecute from "./components/sql_execute";
import GerarCard from "./components/gerar_card";
import Dashboard from "./components/dashboard";
import DashboardAdmCadastro from "./components/dashboard_adm_cadastro";
import FluxoCliente from "./components/fluxo_cliente";
import MinhaRede from "./components/minha_rede";
import Consultor from "./components/consultor";
import CredencialVirtual from "./components/credencial_virtual";
import Formulario from "./components/formulario";
import Component from "./components/component";
import Notificacoes from "./components/notificacoes";
import RelatorioConsultor from "./components/relatorios/relatorio_consultor";
import MinhasEntrevistas from "./components/minhas_entrevistas";
import VerEntrevista from "./components/ver_entrevista";
import EntrevistaConsultor from "./components/entrevista_consultor";
import RelatorioPontuacaoRede from "./components/relatorios/relatorio_pontuacao_rede";
import RelatorioAnaliseRiscoInatividade from "./components/relatorios/relatorio_analise_risco_inatividade";
import RelatorioAscendenciaGenealogica from "./components/relatorios/relatorio_ascendencia_genealogica";
import RelatorioBonusPeriodo from "./components/relatorios/relatorio_bonus_periodo";
import RelatorioGraduacao from "./components/relatorios/relatorio_graduacao";
import utils from "./components/utils";
import RelatorioQualificacao from "./components/relatorios/relatorio_qualificacao";
import RelatorioRankingDistribuidores from "./components/relatorios/relatorio_ranking_distribuidores";
import RelatorioRankingDistribuidoresResumoMesPais from "./components/relatorios/relatorio_ranking_distribuidores_resumo_mes_pais";
import RelatorioExtratoPontuacaoIndividualSintetico from "./components/relatorios/relatorio_extrato_pontuacao_individual_sintetico";
import RelatorioExtratoPontuacaoIndividualAnalitico from "./components/relatorios/relatorio_extrato_pontuacao_individual_analitico";
import RelatorioEventos from "./components/relatorios/relatorio_eventos";
import RelatorioValidacaoRede from "./components/relatorios/relatorio_validacao_rede";
import RelatorioConferenciaSpvGraduacaoPedido from "./components/relatorios/relatorio_conferencia_spv_graduacao_pedido";
import RelatorioPontosVinculados from "./components/relatorios/relatorio_pontos_vinculados";
import TrocarPai from "./components/trocar_pai";
import TransferenciaMovimentacao from "./components/transferencia_movimentacao";
import Evento from "./components/evento";
import Eventos from "./components/eventos";
import MapaPontos from "./components/mapa_pontos";
import OcSPV from "./components/ocspv";
import TesteRotinaGraduacao from "./components/teste_rotina_graduacao";
import RelatorioValidacaoGraduacao from "./components/relatorios/relatorio_validacao_graduacao";
import Contratos from "./components/contratos"
import VerEntrevistaCliente from "./components/ver_entrevista_cliente";
import ContratosPublico from "./components/public/contratos_publico";
import BuscaRma from "./components/relatorios/relatorio_rma";
import RelatorioApuracaoSpv from "./components/relatorios/relatorio_apuracao_spv";
import RelatorioBonusIndividualPeriodo from "./components/relatorios/relatorio_bonus_individual_periodo";
import SolicitarLoja from "./components/solicitar_loja";
import SolicitarLojaConsultor from "./components/solicitar_loja_consultor";
import Rankings from "./components/rankings";
import MeusOrcamentos from "./components/meus_orcamentos";
import Credenciamento from "./components/credenciamento";
import Blog from "./components/Blog";
import BlogTvNipponflex from "./components/BlogTvNipponflex";
import BlogCampanhas from "./components/Blog_campanhas";
import Assinatura from "./components/assinatura";
import PrepararContratos from "./components/preparar_contratos";
import External from "./components/external";
import TransmissaoAoVivo from "./components/transmissao_ao_vivo";
import CampanhasPremiacaoCupons from "./components/campanhas_premiacao_cupons";
import ClonagemCliente from "./components/clonagem_cliente";
import CrudRedeDNA from "./components/rede_dna";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = function () {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/esqueci_senha" component={EsqueciSenha} />
        <Route exact path="/recuperar_senha" component={RecuperarSenha} />
        <Route exact path="/welcome" component={RecuperarSenha} />
        <Route path="/public/contracts" component={ContratosPublico} />

        <PrivateRoute path="/fluxo/clientes" component={FluxoCliente} />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/dashboard_adm_cadastro" component={DashboardAdmCadastro} />
        <PrivateRoute path="/noticias" component={Blog} />
        <PrivateRoute path="/tv_nipponflex" component={BlogTvNipponflex} />
        <PrivateRoute path="/external/:name" component={External} />
        <PrivateRoute path="/campanhas" component={BlogCampanhas} />
        <PrivateRoute path="/crud" component={Generic} />
        <PrivateRoute path="/report/relatorio_pontuacao_rede" component={RelatorioPontuacaoRede} />
        <PrivateRoute path="/report/relatorio_analise_risco_inatividade" component={RelatorioAnaliseRiscoInatividade} />
        <PrivateRoute path="/report/relatorio_ascendencia_genealogica" component={RelatorioAscendenciaGenealogica} />
        <PrivateRoute path="/report/relatorio_bonus_periodo" component={RelatorioBonusPeriodo} />
        <PrivateRoute path="/report/relatorio_extrato_pontuacao_individual_sintetico" component={RelatorioExtratoPontuacaoIndividualSintetico} />
        <PrivateRoute path="/report/relatorio_extrato_pontuacao_individual_analitico" component={RelatorioExtratoPontuacaoIndividualAnalitico} />
        <PrivateRoute path="/report/relatorio_graduacao" component={RelatorioGraduacao} />
        <PrivateRoute path="/report/relatorio_qualificacao" component={RelatorioQualificacao} />
        <PrivateRoute path="/report/relatorio_ranking_distribuidores" component={RelatorioRankingDistribuidores} />
        <PrivateRoute path="/report/relatorio_ranking_distribuidores_resumo_mes_pais" component={RelatorioRankingDistribuidoresResumoMesPais} />
        <PrivateRoute path="/report/relatorio_eventos" component={RelatorioEventos} />
        <PrivateRoute path="/report/relatorio_validacao_rede" component={RelatorioValidacaoRede} />
        <PrivateRoute path="/report/relatorio_conferencia_spv_graduacao_pedido" component={RelatorioConferenciaSpvGraduacaoPedido} />
        <PrivateRoute path="/report/relatorio_validacao_graduacao" component={RelatorioValidacaoGraduacao} />
        <PrivateRoute path="/report/relatorio_apuracao_spv" component={RelatorioApuracaoSpv} />
        <PrivateRoute path="/report/relatorio_bonus_individual_periodo" component={RelatorioBonusIndividualPeriodo} />
        <PrivateRoute path="/report/relatorio_pontos_vinculados" component={RelatorioPontosVinculados} />
        <PrivateRoute path="/report" component={Report} />
        <PrivateRoute path="/produto/classificacao" component={Classificacao} />
        <PrivateRoute path="/carrinho/pagamento" component={Pagamento} />
        <PrivateRoute path="/carrinho/pedido" component={Pedido} />
        <PrivateRoute path="/carrinho/produto/" component={Produto} />
        <PrivateRoute path="/carrinho/favoritos" component={Favoritos} />
        <PrivateRoute path="/carrinho/promocoes" component={Promocoes} />
        <PrivateRoute path="/carrinho" component={Carrinho} />
        <PrivateRoute path="/meus_dados" component={MeusDados} />
        <PrivateRoute path="/meus_pedidos" component={MeusPedidos} />
        <PrivateRoute path="/pagamentos_pendentes" component={PagamentosPendente} />
        <PrivateRoute path="/servicos/solicitar_loja" component={SolicitarLoja} />
        <PrivateRoute path="/servicos/solicitar_loja_consultor" component={SolicitarLojaConsultor} />
        <PrivateRoute path="/swagger" component={Swagger} />
        <PrivateRoute path="/minha_rede" component={MinhaRede} />
        <PrivateRoute path="/sql_execute" component={SqlExecute} />
        <PrivateRoute path="/gerar_card" component={GerarCard} />
        <PrivateRoute path="/consultores" component={Consultor} />
        <PrivateRoute path="/credencial_virtual" component={CredencialVirtual} />
        <PrivateRoute path="/formulario" component={Formulario} />
        <PrivateRoute path="/component" component={Component} />
        <PrivateRoute path="/entrevistas/consultor/" component={EntrevistaConsultor} />
        <PrivateRoute path="/ver_entrevista" component={VerEntrevista} />
        <PrivateRoute path="/relatorios/consultor" component={RelatorioConsultor} />
        <PrivateRoute path="/entrevistas" component={MinhasEntrevistas} />
        <PrivateRoute path="/notificacoes" component={Notificacoes} />
        <PrivateRoute path="/clientes/trocar_pai" component={TrocarPai} />
        <PrivateRoute path="/clientes/transferencia_movimentacao" component={TransferenciaMovimentacao} />
        <PrivateRoute path="/ver_entrevista_cliente/:id" component={VerEntrevistaCliente} />
        <PrivateRoute path="/orcamentos_cliente/:id" component={MeusOrcamentos} />
        <PrivateRoute path="/evento/" component={Evento} />
        <PrivateRoute path="/informativos" component={Informativos} />
        <Route path="/contratos/:id_contrato/assinaturas/:id_cliente/:fase" component={Assinatura} />
        <PrivateRoute path="/contratos/preparar/:id_cliente" component={PrepararContratos} />
        <PrivateRoute path="/contratos" component={Contratos} />
        <PrivateRoute path="/eventos/credenciamento" component={Credenciamento} />
        <PrivateRoute path="/eventos" component={Eventos} />
        <PrivateRoute path="/ocspv" component={OcSPV} />
        <PrivateRoute path="/relatorios/busca_rma" component={BuscaRma} />
        <PrivateRoute path="/relatorios/mapa_pontos" component={MapaPontos} />
        <PrivateRoute path="/teste_rotina_graduacao" component={TesteRotinaGraduacao} />
        <PrivateRoute path="/rankings" component={Rankings} />
        <PrivateRoute path="/alterar_senha" component={RecuperarSenha} />
        <PrivateRoute path="/transmissao_ao_vivo" component={TransmissaoAoVivo} />
        <PrivateRoute path="/campanhas_premiacao_cupons" component={CampanhasPremiacaoCupons} />
        <PrivateRoute path="/clonagem_cliente" component={ClonagemCliente} />
        <PrivateRoute path="/rede_dna" component={CrudRedeDNA} />
        <PrivateRoute path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  )
};

export default Routes;